
import React, { useEffect, useState } from 'react';
import './research.css';
import {motion as m} from 'framer-motion'


const Research2 = () => {
    return (
    <m.div initial={{ x: "10%" , opacity:"0%"}}
    animate={{ x: "0%", opacity:"100%" }}
    transition={{type:"spring", duration: 1.2, ease: "easeOut" }}
    exit={{ opacity: 0 }}>
        <div className='file'>
            <div className='research-title'>Research Papers</div>
                <div className='under'>During my study, I have written several papers. Note that none of these papers have been published 
                    except for the last written paper. In case you have any questions, feel free to <a href="https://www.linkedin.com/in/julian-pasveer-466ba125a/">contact</a> me.</div>
                    <br></br>
                        <ul>
                            <li>
                                <div class="date">August, 2023</div>
                                <div class="title2">Adapting MTO to Radio Astronomical Data: Reliability Measures</div>
                                <div class="descr">J. Pasveer, "Adapting MTO to Radio Astronomical Data: Reliability Measures", Aug. 2023<br>
                                </br> 
                                    <a href={"/Bachelor_Project_MTO_Julian_Pasveer_Final.pdf"} target="_blank" rel="noreferrer">Open</a>
                                </div>
                                
                            </li>
                            <li>
                                <div class="date">May 2023</div>
                                <div class="title2">Deep Learning for Leakage Detection in Water Networks: A Comparative Study</div>
                                <div class="descr">C. van Riemsdijk and J. Pasveer, "Deep Learning for Leakage Detection in Water Networks: A Comparative Study", May 2023
                                <br>
                                </br>
                                    <div className='linker'>Link will be provided soon</div>
                                </div>
                            </li>
                            <li>
                                <div class="date">Februari, 2023</div>
                                <div class="title2">Exploring the cosine similarity for automated relating of architectural issues and emails in mailing lists</div>
                                <div class="descr">B. Pijnacker, J. van der Zwaag, and J. Pasveer, “Exploring the cosine similarity for automated relating of architectural issues and emails in mailing lists,” Feb. 2023
                                <br>
                                </br>
                                <a href={"/Relate_architectural_issues_and_emails_in_mailing_lists (2).pdf"} target="_blank" rel="noreferrer">Open</a>
                                </div>
                            </li>
                            <li>
                                <div class="date">December, 2022</div>
                                <div class="title2">Tools for Measuring and Monitoring the Energy Efficiency of Software Systems: A Rapid Review</div>
                                <div class="descr">B. Pijnacker, J. van der Zwaag, and J. Pasveer, "Tools for Measuring and Monitoring the Energy Efficiency of Software Systems: A Rapid Review", Dec. 2022
                                <br>
                                </br>
                                <a href={"/Rapid_Review__Which_tools_can_I_use_to_measure_and_monitor_the_energy_efficiency_of_my_software_system_.pdf"} target="_blank" rel="noreferrer">Open</a>
                                </div>
                            </li>
                            <li>
                                <div class="date">2020</div>
                                <div class="title2">Solutions to large scale DNA and RNA processing</div>
                                <div class="descr">Y. Molema, D. Scheepstra, M. Kruijer, W. Haverkort, J. Klooster, J. Pasveer and R. Wuijster, "Solutions to large scale DNA and RNA processing", 2020
                                <br>
                                </br>
                                <a href={"/Research_Paper_SC.pdf"} target="_blank" rel="noreferrer">Open</a>
                                </div>
                            </li>
                        </ul>
        </div>
        </m.div>
    );
  };
  
  export default Research2;