import './App.css';
import Section1 from "./home_page/Section1.js";
import {motion as m} from 'framer-motion';


    function Home() {
      document.title = "Julian";
      return (
        <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        exit={{opacity: 1}}>
      <Section1 />
    </m.div>
      );
    }
    
    export default Home;