import React from "react";
import { useNavigate } from "react-router-dom";
import './Section1.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Typewriter } from 'react-simple-typewriter'
import foto4 from './profile.png'
import {motion as m} from 'framer-motion'

export default function Section1() {
  let navigate = useNavigate();
  const handleType = (count = 4) => {
    // access word count number
  console.log(count)}
  return (
    <div className="section-1">
      <m.div
      initial={{opacity: 0}}
      animate={{opacity:1}}
      transition={{duration: 1, ease: "easeOut"}}>
        <img src={foto4} height="200px"></img>
      </m.div>
      <m.div
        initial={{opacity: 0}}
        animate={{opacity:1}}
        transition={{duration: 1, ease: "easeOut"}}
        className="title">
        <span>Julian Pasveer</span>
      </m.div>

      <h1 style={{ color: 'white'}}>
        <m.div
        initial={{opacity: 0}}
        animate={{opacity:1}}
        transition={{duration: 2.3, ease: "easeOut"}} className="sub-title">I am a {' '}
        <span style={{ color: '#359ad8', fontWeight: 'bold'}}>
          {/* Style will be inherited from the parent element */}
          <Typewriter
            words={['Developer', 'Student', 'Tutor']}
            loop={true}
            cursor
            cursorStyle='_'
            typeSpeed={70}
            deleteSpeed={55}
            delaySpeed={1000}
            onType={handleType}
          />
        </span>
        </m.div>
      </h1>
      <m.div
      initial={{y: "100%"}}
      animate={{y: "0%"}}
      transition={{type: "spring", duration: 1.3, ease: "easeOut"}}>
        <div className="buttons">
      <a style={{ textDecoration: "none" }} href="/about">
          <div className="button-three">About Me</div>
        </a>
      </div>
      <div className="buttons">
      <div
          className="button-secondary"
          onClick={() => {
            navigate("/research");
          }}
        >
          Research Papers
        </div>
      </div>
      <div className="buttons">
      <div
          className="button-secondary"
          onClick={() => {
            navigate("/summaries");
          }}
        >
          Summaries
        </div>
      </div>
      </m.div>
      
    </div>
  );
}
