import './App.css';
import React, {useState} from 'react';
import './summary.css'
import {motion as m} from 'framer-motion';



function Summary() {
      document.title = "Summaries";
      const [numPages, setNumPages] = useState(null);
      const [pageNumber, setPageNumber] = useState(1);

      function changePage(offSet) {
            setPageNumber(prevPageNumber => prevPageNumber + offSet)
      }

      function changePageBack() {
            changePage(-1)
      }
      function changePageNext() {
            changePage(+1)
      }

      function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
      }
          

      return (
            <><m.div
            initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        exit={{opacity: 1}} className='entire'>
<h1 className='h1edit'>Summaries</h1>
<br></br>
<h2 className='h2edit'>I have written several papers during my studies. Most are related to courses I participated in, some <br></br> are 
ones I wrote myself for the purpose of self-study within the field of Computing Science.
I am currently in the <br>
</br>process of completing the summaries for posting on this platform; hence, none are currently available</h2>
<br></br>
<table class="table table-hover table-dark flex">
  <thead>
    <tr>
      <th scope="col">Study</th>
      <th scope="col">Study Year</th>
      <th scope="col">Course Name</th>
      <th scope='col'>Download</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope='row'>Quantum physics & Relativity</th>
      <th scope="row">3 (BSc)</th>
      <td>Einstein's Universe</td>
      <td>*Will be added soon*</td>
      {/* <td><Link to="/Summary_Einstein_s_Universe.pdf" target="_blank" download>Download</Link></td> */}
    </tr>
    <tr>
    <th scope='row'>Computing Science</th>
      <th scope="row">1 (BSc)</th>
      <td>Discrete Structure</td>
      <td>*Will be added soon*</td>
      {/* <td><Link to="/Discrete_Structures_Summary_Julian_Pasveer.pdf" target="_blank" download>Download</Link></td> */}
    </tr>
    {/*
    <tr>
    <th scope='row'>Computing Science</th>
      <th scope="row">1 (BSc)</th>
      <td>Discrete Structure</td>
      <td>*Will be added soon*</td>
    </tr>
    <tr>
    <th scope='row'>Computing Science</th>
      <th scope="row">1 (BSc)</th>
      <td>Discrete Structure</td>
      <td>*Will be added soon*</td>
    </tr>
    <tr>
    <th scope='row'>Computing Science</th>
      <th scope="row">1 (BSc)</th>
      <td>Discrete Structure</td>
      <td>*Will be added soon*</td>
    </tr>
     <tr>
    <th scope='row'>Computing Science</th>
      <th scope="row">1 (BSc)</th>
      <td>Discrete Structure</td>
      <td>*Will be added soon*</td>
    </tr>
    <tr>
    <th scope='row'>Computing Science</th>
      <th scope="row">1 (BSc)</th>
      <td>Discrete Structure</td>
      <td>*Will be added soon*</td>
    </tr>
    <tr>
    <th scope='row'>Computing Science</th>
      <th scope="row">1 (BSc)</th>
      <td>Discrete Structure</td>
      <td>*Will be added soon*</td>
    </tr>
    <tr>
    <th scope='row'>Computing Science</th>
      <th scope="row">1 (BSc)</th>
      <td>Discrete Structure</td>
      <td>*Will be added soon*</td>
    </tr> */}
  </tbody>
</table>



            </m.div></>
            
      );
}
    
export default Summary;