import React from 'react';
import './AboutMe.css';
import { easeOut, motion as m } from 'framer-motion';
import prof2 from './prof2.JPG';

const AboutMe = () => {
  return (
    <m.div
      initial={{ y: "40%", opacity:"0%" }}
      animate={{ y: "0%", opacity: "100%" }}
      transition={{type:"spring", duration: 1.2, ease: "easeOut" }}
      exit={{ opacity: 0 }}
      className='personal-page'
    >
      <div className="about-me-section">
        <div className="left-box">
          <h1 className='about'>About Me</h1>
          <p className='textbox'>Hi! My name is Julian Pasveer, a 24-year-old living in the Netherlands. I'm currently studying at the RUG 
            (University of Groningen). In my free time, I enjoy going to the gym and practicing fencing, two of my favorite hobbies. </p>
        </div>
        <div className="right-box">
          <img src={prof2} alt="Julian" />
        </div>
      </div>
      <div className="additional-boxes">
        <div className="info-box">
          <h2>Education</h2>
          <p className="textbox">I have successfully completed my pre-university education (VWO) with a focus on the Natural Sciences and Technology curriculum in 2019.
          Afterwards, I attended the University where I pursued my Bachelor in Computing Science, which I completed in 2023. Currently, I am working 
          towards obtaining my Master in Software Engineering & Distributed Systems. I aim to complete this in June 2025.</p>
        </div>
        <div className="info-box">
          <h2>Skills</h2>
          <p className='textbox'>During my studies and while working on assignments, I acquired a wide range of skills. I have experience with several programming 
            languages, including C, C#, C++, Python, R, Haskell, Java, Javascript, Typescript, and more. Additionally, I am familiar with popular frameworks 
            such as React and Laravel. Furthermore, I am familiar with Docker. I am fluent in both Dutch and English and speak decent German as well as Spanish. I am presently engaged in the acquisition of 
            proficiency in Chinese (Mandarin) and am concurrently advancing my proficiency in Spanish with the goal of achieving fluency. Additionally, 
            I possess expertise in the field of education, serving as a tutor specializing in the instruction of Mathematics, Physics, and Chemistry to 
            high school students.</p>
        </div>
        {/* <div className="info-box">
          <h2>Experience</h2>
          <p>I</p>
        </div> */}
        {/* Add more additional boxes as needed */}
      </div>
      {/* <div className='extra-box'>
        <h2>Work Experience</h2>
        <br></br>
        <div class="experience-item">
            <div class="experience-title">Shelf stocker at Jumbo</div>
            <div class="experience-date">January 2020 - Present</div>
            <div class="experience-description">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin euismod vehicula metus, eget ullamcorper tortor bibendum id.</p>
                <p>Donec tincidunt quam a nunc pharetra, vel auctor mi vestibulum. Duis vestibulum, libero eu sollicitudin.</p>
            </div>
        </div>

        <div class="experience-item">
            <div class="experience-title">Internship at ABC Company</div>
            <div class="experience-date">May 2019 - December 2019</div>
            <div class="experience-description">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin euismod vehicula metus, eget ullamcorper tortor bibendum id.</p>
                <p>Donec tincidunt quam a nunc pharetra, vel auctor mi vestibulum. Duis vestibulum, libero eu sollicitudin.</p>
            </div>
        </div>
        </div> */}
    </m.div>
  );
};

export default AboutMe;
